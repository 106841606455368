
import React from "react";
import './App.css';
import LuigiClient from '@luigi-project/client';

import styled, { css } from 'styled-components';
import DataTable from 'react-data-table-component';
import SortIcon from "@material-ui/icons/ArrowDownward";


const columns = [
	{
		name: 'Name',
		selector: row => row.name,
		sortable: true,
	},
	{
		name: 'Number',
		selector: row => row.number,
		sortable: true,
	},
];



class App extends React.Component {
   
    // Constructor 
    constructor(props) {
        super(props);
   
        this.state = {
            items: [],
            DataisLoaded: false,
            filterStr: "",
            filterNr: ""
        };
    }
  
    // ComponentDidMount is used to
    // execute the code 
    componentDidMount() {
      LuigiClient.addInitListener(initialContext => {
        fetch(
          "https://api.lpa-ug.de/finance/account",
          {
            headers: {
              'x_token': LuigiClient.getToken(),
            }
          })
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    items: json,
                    DataisLoaded: true
                });
            })
          });
    }


    render() {

        const { DataisLoaded, items, filterStr, filterNr } = this.state;
        if (!DataisLoaded) return <div>
            <h1> Loading.... </h1> </div> ;
   
        const showNew = () => {
          LuigiClient.uxManager().showAlert({  text: "Saved sucessfully",  type: "success",  closeAfter: 3000});
            LuigiClient.linkManager().openAsModal('/intranet/accounts', {title:'Users', size:'m'});
        };

        const refresh = () => {
          fetch(
            "https://api.lpa-ug.de/finance/account",
            {
              headers: {
                'x_token': LuigiClient.getToken(),
              }
            })
              .then((res) => res.json())
              .then((json) => {
                  this.setState({
                      items: json,
                      DataisLoaded: true
                  });
              })
           
        };

        const filteredElements = items
          .filter(e => e.name.toLowerCase().includes(filterStr.toLowerCase()) && e.number.toString().toLowerCase().includes(filterNr.toLowerCase()));

      
        return (
          <div>
            
            <button onClick={showNew}>
              New
            </button>
            <button onClick={refresh}>
              Refresh
            </button>

            <p>Schnellfilter (Name):</p>
            <input
            type="text"
            value={ filterStr }
            onChange={ e => this.setState({ items: items, DataisLoaded: DataisLoaded, filterStr: e.target.value, filterNr: filterNr }) } />

            <p>Schnellfilter (Konto):</p>
            <input
            type="text"
            value={ filterNr }
            onChange={ e => this.setState({ items: items, DataisLoaded: DataisLoaded, filterStr: filterStr, filterNr: e.target.value }) } />

            <DataTable
              title="Accounts"
              columns={columns}
              data={filteredElements}
              defaultSortFieldId={1}
              sortIcon={<SortIcon />}
              pagination
              selectableRows
            />
        </div>
    );
}
}
   
export default App;